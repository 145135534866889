import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import InlineCheckBox from "../InlineCheckBox/InlineCheckBox";
import {
  instituteTypes,
  SDGs,
  WORLD_REGION_CHOICES,
  searchOptions
} from "utils/choices.jsx";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

const instituteTypeOptions = instituteTypes.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const SDGOptions = SDGs.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const regionOptions = WORLD_REGION_CHOICES.map(dt => ({
  label: dt.text,
  value: dt.value
}));

const dashboardStyle = {
  cardTitle,
  filter: {
    marginBottom: "10px"
  },
  mapBox: {
    width: "auto",
    height: "400px"
  },
  headerBg: {
    backgroundColor: "#0000001f"
  },
  message: {
    color: "#6f6262a3",
    width: "100%",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

const SearchComponent = props => {
  // eslint-disable-next-line no-unused-vars
  const { classes } = props;
  const setFilter = (type, val) => {
    props.filterChange && props.filterChange({ type, val });
  };
  return (
    <GridItem xs={12} sm={12} md={12} style={{ marginLeft: "20px" }}>
      <InlineCheckBox
        label="Search includes"
        checkBox={searchOptions}
        defaultChecked={searchOptions.map(searchOption => searchOption.key)}
        showClickedItem={item => props.checkChange && props.checkChange(item)}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Select
            isMulti
            options={instituteTypeOptions}
            onChange={values => {
              setFilter("instituteType", values);
            }}
            placeholder={<span>Type</span>}
            className={classes.filter}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Select
            isMulti
            placeholder={<span>SDG</span>}
            onChange={values => setFilter("sdg", values)}
            options={SDGOptions}
            className={classes.filter}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Select
            isMulti
            placeholder={<span>Region</span>}
            onChange={values => setFilter("region", values)}
            options={regionOptions}
            className={classes.filter}
          />
        </GridItem>
      </GridContainer>
    </GridItem>
  );
};

SearchComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  filterChange: PropTypes.func,
  checkChange: PropTypes.func
};

export default withStyles(dashboardStyle)(SearchComponent);
