import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Pageview from "@material-ui/icons/Pageview";
import Public from "@material-ui/icons/Public";
import Place from "@material-ui/icons/Place";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";

import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import OverflowTip from "../../utils/OverflowTip"

//import defaultImage from "assets/img/image_placeholder.jpg";
import defaultLogo from "assets/img/sdg-bg.png";
import { MEDIA_ROOT } from "config.js";
import Button from "@material-ui/core/Button";
import ViewIcon from "@material-ui/icons/Visibility";

import { buttonColorScheme } from "../../../src/assets/jss/material-dashboard-react/layouts/appstyleconfigs.jsx"
// import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const dashboardStyle = {
  cardHover: {
    height: "auto"
  },
  instituteContainer: {

    overflow: "hidden",
    display: "block"
  },
  imgContainer: {
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    height: "200px"
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    border: "0",
    aspectRatio: 1,
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    objectFit: "contain"
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "3.5rem"
  },
  userRolescontainer: {
    paddingTop: "5px"
  },
  cardFooter: {
    display: "inline-block",
    width: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
  },
  buttonStyle: {
    textAlign: "left",
    width: "100%"
  },
  headerStyle: {
    fontSize: "1.20em",
    fontWeight: "bolder"
  },
  subtitle: {
    padding: "0rem 1.25rem"
  }
};

class InstituteSummary extends React.Component {
  render() {
    // eslint-disable-next-line no-unused-vars
    const {
      classes,
      instituteData,
      renderExtraButtons,
      renderStatus,
      isInstituteList,
      renderAdminView
    } = this.props;

    return (
      <GridItem
        xs={12}
        sm={12}
        md={4}
        key={`institute-${instituteData.id}`}
        className={classes.instituteContainer}
      >
        <Card product className={classes.cardHover}>
          <CardHeader className={classes.cardHeader}>
            <OverflowTip>
              <a
                style={dashboardStyle.headerStyle}
                href={`/institutes/${instituteData.id}`}
              >
                {" "}{instituteData.name}
              </a>{" "}
            </OverflowTip>
            {instituteData.type && <div className={classes.instituteTypeContainer}>
              <h6>{instituteData.type}</h6>
            </div>}
            {renderStatus && <div className={classes.userRolescontainer}>
              {renderStatus()}
            </div>}
            {renderAdminView && <div className={classes.userRolescontainer}>
              {renderAdminView()}
            </div>}
          </CardHeader>
          <CardBody>
            <div className={classes.imgContainer}>
              <img
                src={
                  instituteData.avatar
                    ? `${MEDIA_ROOT}${instituteData.avatar}`
                    : defaultLogo
                }
                alt="..."
                className={classes.img}
              />
            </div>
            <br />
            {!isInstituteList ? (
              <div>
                <span>
                  <Tooltip
                    id="tooltip-top"
                    title="View Profile"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="transparent"
                      simple
                      justIcon
                      onClick={() =>
                        window.open(`/institutes/${instituteData.id}`, "_new")
                      }
                    >
                      <Pageview className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  </span>
                  <Tooltip
                    id="tooltip-top"
                    title="Visit Webpage"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="transparent"
                      simple
                      justIcon
                      onClick={() => window.open(instituteData.website_url, "_new")}
                    >
                      <Public className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>

                  {instituteData.is_following ? (
                    <Tooltip
                      id="tooltip-top"
                      title="unfollow"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="danger"
                        simple
                        justIcon
                        onClick={this.props.unfollowInstitute}
                      >
                        <StarBorder className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      id="tooltip-top"
                      title="Follow"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="success"
                        simple
                        justIcon
                        onClick={this.props.followInstitute}
                      >
                        <Star className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  )}
                  {renderExtraButtons && renderExtraButtons()}

                  {(instituteData.mission && instituteData.mission !== undefined) ? <OverflowTip>{instituteData.mission}</OverflowTip> : <div style={{whiteSpace: "pre-wrap"}}>{"\n"}</div>}
              </div>
            ) : null}
          </CardBody>
          <CardFooter product>
            {instituteData.address ?
              (
                <div className={classes.price}>
                  <OverflowTip>
                    <Place />{" "}
                    <small>
                      {
                        `${instituteData.address.city_name}, 
                    ${instituteData.address.country_name}`
                      }
                    </small>
                  </OverflowTip>
                </div>
              ) : <div style={{ whiteSpace: "pre-wrap" }}>{"\n"}</div>
            }
            {isInstituteList ?
              (
                <div className={classes.buttonStyle}>
                  <span>
                    <Button 
                    color={ buttonColorScheme.infoButton }
                    startIcon = {<ViewIcon />}
                      onClick={() => (window.location = (`/institutes/${instituteData.id}`))}>
                        View Institute
                      </Button>
                  </span>
                </div>
              ) : null}
          </CardFooter>
        </Card>
      </GridItem>
    );
  }
}

InstituteSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  instituteData: PropTypes.object.isRequired,
  unfollowInstitute: PropTypes.object.isRequired,
  followInstitute: PropTypes.object.isRequired,
  renderExtraButtons: PropTypes.func,
  renderStatus: PropTypes.func
};

export default withStyles(dashboardStyle)(InstituteSummary);
