/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/bg.jpg";

import { staticData } from "config.js";
import { Authenticated } from "containers/authenticated.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      isProjectDashboardOpen: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  switchRoutes = () => {
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.to} key={key} />;
          return (
            <Route
              path={prop.path}
              render={(props) => (
                <prop.component
                  searchVal={this.state.searchVal}
                  filterVal={this.state.filterVal}
                  checkVal={this.state.checkVal}
                  isProjectDashboard={this.setProjectDashboard}
                  onRef={ref => (this.child = ref)}
                  {...props}
                />
              )}
              key={key}
            />
          );
        })}
      </Switch>
    );
  };
  updateSearchVal = val => {
    this.setState({ searchVal: val });
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  showBackButton = e => {
    return this.props.location.pathname.includes("/institutes/") || 
    this.props.location.pathname.includes("/projects/") ||
    this.props.location.pathname.includes("/quiz/")
  }
  setProjectDashboard = isOpen => {
    if(!isOpen) this.child.showList()
    this.setState({isProjectDashboardOpen: isOpen});
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <Authenticated>
        <div className={classes.wrapper} style={{position: "inherit"}}>
          <Sidebar
            routes={dashboardRoutes.filter(prop => prop.hide !== true)}
            logoText={staticData.company.logoText}
            logo={staticData.company.logoSmall}
            image={image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="blue"
            {...rest}
          />
          <div style={{position: "inherit"}} className={classes.mainPanel} ref="mainPanel">
            <Header
              routes={dashboardRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              onSearchClick={this.updateSearchVal}
              filterValue={i => this.setState({ filterVal: i })}
              checkValues={i => this.setState({ checkVal: i })}
              isProjectDashboardOpen={this.state.isProjectDashboardOpen}
              backButton={this.showBackButton}
              isProjectDashboard={this.setProjectDashboard}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {this.getRoute() ? (
              <div className={classes.content} style={{marginTop: "0px"}}>
                <div className={classes.container}>{this.switchRoutes()}</div>
              </div>
            ) : (
              <div className={classes.map}>{this.switchRoutes()}</div>
            )}
            {this.getRoute() ? <Footer /> : null}
          </div>
        </div>
      </Authenticated>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(App);
