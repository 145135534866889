import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import InstituteSummary from "components/InstituteSummary/InstituteSummary.jsx";
import EventSummary from "components/EventSummary/EventSummary.jsx";
import UserSummary from "components/UserSummary/UserSummary.jsx";
import Paginate from "components/Pagination/CustomPagination.js";
import { followInstitute } from "services/instituteDataProvider.js";
import { searchApi } from "services/searchProvider.js";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";

import { searchOptions } from "utils/choices.jsx";

const dashboardStyle = {
  cardTitle,
  filter: {
    marginBottom: "10px"
  },
  mapBox: {
    width: "auto",
    height: "400px"
  },
  message: {
    color: "#6f6262a3",
    width: "100%",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  searchResultsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: 0
  },
  searchResultsHeader: {
    backgroundColor: "#74c186"
  },
  searchResults: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "baseline",
    padding: "10px 2px 2px 2px",
    minHeight: "calc(100vh - 316px)"
  },
  searchResultItem: {
    "-webkit-flex": 1,
    "-ms-flex": 1,
    flex: 1
  },
  searchResultsFooter: {
    display: "flex",
    justifyContent: "flex-end"
  }
};

class Home extends React.Component {
  state = {
    results: [],
    filteredData: [],
    pageNumber: 0,
    pageSize: 9,
    lookFor: searchOptions.map(sp => sp.key),
    type: [],
    searchTerm: "",
    pageCount: 0,
    checked: false,
    filterTerm: "",
    checkIncludes: []
  };

  componentDidMount = () => {
    if (this.props.searchVal) {
      this.setState({ searchTerm: this.props.searchVal }, () =>
        this.searchCall()
      );
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchVal !== prevState.searchTerm) {
      return { searchTerm: nextProps.searchVal };
    }
    if (nextProps.filterVal !== prevState.filterTerm) {
      return { filterTerm: nextProps.filterVal };
    }
    if (nextProps.checkVal !== prevState.lookFor) {
      return { lookFor: nextProps.checkVal };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchVal !== this.props.searchVal) {
      this.setState({ searchTerm: prevProps.searchVal }, () =>
        this.searchCall()
      );
    }
    if (prevProps.filterVal !== this.props.filterVal) {
      this.setState({ filterTerm: prevProps.filterVal }, () =>
        this.handleFilterSelectionChange(
          this.props.filterVal.type,
          this.props.filterVal.val
        )
      );
    }
    if (prevProps.checkVal !== this.props.checkVal) {
      this.setState({ lookFor: prevProps.checkVal, pageNumber: 0 }, () =>
        this.searchCall()
      );
    }
  }

  componentWillUnmount = () => {};

  switchChange = () => {
    this.setState({ checked: !this.state.checked });
  };

  getPayload = () => {
    const {
      lookFor,
      pageNumber,
      pageSize,
      type,
      searchTerm: name
    } = this.state;
    let payload = {
      name,
      type,
      lookFor,
      pageNumber,
      pageSize
    };
    return payload;
  };

  searchCall = () => {
    let payload = this.getPayload();
    payload.name = payload.name === undefined ? "" : payload.name
    searchApi(this.props.axios, payload).then(data => {
      let searchResult = data.results;
      let resultCount = parseInt(data.total);

      let results = [];
      results = results.concat(searchResult.projects.map(x => { return {resultType: "project", ...x} }));
      results = results.concat(searchResult.members.map(x => { return {resultType: "member", ...x} }));
      results = results.concat(searchResult.institutes.map(x => { return {resultType: "institute", ...x} }));
      results = results.concat(searchResult.events.map(x => { return {resultType: "event", ...x} }));

      this.setState({
        results: results,
        filteredData: results,
        searchDataAvailable: true,
        pageCount: resultCount
      });
    });
  };

  handleChange = e => {
    let val = e.target.value;
    this.setState({ searchTerm: val }, () => {
      this.searchCall();
    });
  };

  handleFilterSelectionChange = (type, values, action) => {
    if (this.state.searchDataAvailable !== true) {
      this.searchCall();
    }
    let filteredData = this.state.results;
    let filterVals = values || [];
    filterVals = filterVals.map(dt => dt.value);
    if (type === "instituteType") {
      this.setState({ type: filterVals, pageNumber: 0 }, () => {
        this.searchCall();
      });
      return;
    }
    this.setState({ [`filter${type}`]: filterVals }, () => {
      filteredData = this.filterInstituteType(
        this.state.filterinstituteType || [],
        filteredData
      );
      filteredData = this.filterSDGs(this.state.filtersdg || [], filteredData);
      filteredData = this.filterRegions(
        this.state.filterregion || [],
        filteredData
      );
      this.setState({ filteredData: filteredData });
    });
  };

  filterInstituteType(filterValues, source) {
    // console.log("filterInstituteType", values, source);
    if (filterValues.length > 0) {
      let filteredResults = this.state.results.filter(instituteData => {
        const target = instituteData.type;
        return filterValues.filter(dt => dt === target).length > 0;
      });
      // console.log("filteredResults", filteredResults);
      return filteredResults;
    }
    return source;
  }

  filterSDGs(filterValues, source) {
    if (filterValues.length > 0) {
      // console.log("values", filterValues);
      let filteredResults = this.state.results.filter(instituteData => {
        const target = instituteData.sustainable_development_goals;
        // console.log("target", target);
        return (
          filterValues.filter(dt => {
            return target.filter(tval => tval === dt).length > 0;
          }).length > 0
        );
      });
      // console.log("filteredResults", filteredResults);
      return filteredResults;
    }
    return source;
  }

  filterRegions(filterValues, source) {
    if (filterValues.length > 0) {
      // console.log("values", filterValues);
      let filteredResults = this.state.results.filter(instituteData => {
        const target = instituteData.address.region_name;
        // console.log("target", target);
        return filterValues.filter(dt => dt === target).length > 0;
      });
      // console.log("filteredResults", filteredResults);
      return filteredResults;
    }
    return source;
  }

  followInstitute = (idx, instituteId) => e => {
    followInstitute(this.props.axios, instituteId, true).then(data => {
      // console.log("followInstitute", data);
      let institutes = this.state.results;
      institutes[idx].is_following = true;
      this.setState({ results: institutes });
    });
  };

  unfollowInstitute = (idx, instituteId) => e => {
    followInstitute(this.props.axios, instituteId, false).then(data => {
      // console.log("unfollowInstitute", data);
      let institutes = this.state.results;
      institutes[idx].is_following = false;
      this.setState({ results: institutes });
    });
  };

  isValidSearch() {
    let payload = this.getPayload();
    if ((payload.name !== undefined && payload.name.length > 0) || payload.type.length > 0) return true
    return false;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const filteredData = this.state.filteredData;

    if (!this.isValidSearch()) {
      return null;
    }

    return (
      <Card className={classes.searchResultsContainer}>
        <CardHeader className={classes.searchResultsHeader}>
          <h4 className={classes.cardTitle}>Search Results</h4>
        </CardHeader>
        <CardBody className={classes.searchResults}>
          {filteredData.length === 0 ? (
            <div className={classes.message}>
              <h3> *No result Found</h3>
            </div>
          ) : null}
          {filteredData.map((resData, idx) => {
            if (resData.resultType === "institute") {
              return (
                <InstituteSummary
                  className={classes.searchResultItem}
                  key={`institute-${idx}`}
                  instituteData={resData}
                  followInstitute={this.followInstitute(idx, resData.id)}
                  unfollowInstitute={this.unfollowInstitute(
                    idx,
                    resData.id
                  )}
                />
              );
            } else if (resData.resultType === "event") {
              return (<EventSummary
                        key={`event-${idx}`}
                        eventData={resData}
                        selfUser={this.props.user}
                      />);
            } else if (resData.resultType === "member") {
              return (<UserSummary
                        key={`user-${idx}`}
                        userData={resData}
                        selfUser={this.props.user}
                      />);
            }
            return null;
          })}
        </CardBody>
        <CardFooter className={classes.searchResultsFooter}>
          <Paginate
            getPage={page =>
              this.setState({ pageNumber: page - 1 }, () =>
                this.searchCall()
              )
            }
            pageCount={this.state.pageCount}
          />
        </CardFooter>
      </Card>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  axios: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Home);
